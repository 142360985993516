import React from 'react';
import Layout from 'components/Layout';
import Article from 'components/Article';
import Hero from 'components/Hero';
import ContactMe from 'components/ContactMe' ;

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero 
        title="404"        
      ></Hero>
      <Article>
      <h1>Page Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Article>
      <ContactMe></ContactMe>
    </Layout>
  );
;}

export default NotFoundPage
